@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display|Roboto&display=swap);


body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;*/
    font-family: 'Roboto', sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3 {
  font-family: 'Playfair Display', serif;
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.leaflet-container {
    height: 300px;
    width: 75%;
    margin: auto;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* below CSS is for the burger icon */


/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.top-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h2.sectionTitle {
  font-size: 20px;
}

div.horizontalScrollContainer {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

div.horizontalScrollContainer * {
  margin:0;
}

div.horizontalScrollContainer p.price {
  color: blue;
  font-weight: 700;
  font-size: 20px;
}

div.horizontalScrollContainer h3 {
  font-size: 16px;
}

div.horizontalScrollContainer > div {
  flex: 0 0 auto;
  width: 180px;
}

div.content {
  margin-top: 60px;
}

h2 {
  font-size: 1rem;
}

div.fixed-below-menu {
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 500;
  background-color: white;
}

div.content-page {
  position: absolute;
  top: 60px;
  width: 100%;
  z-index: 100;
  background-color: white;
}

div.footer {
  background-color: black;
  color: white;
  text-align: center;
  padding-top: 15px;
}

div.footer > ul {
  list-style-type: none;
  margin: 0;
  margin-bottom: 32px;
  padding: 0;
}

div.footer > h3 {
  font-size: 1rem;
  margin-bottom: 0;
  font-weight: 700;
}

div.footer * li {
  font-size: 0.8rem;
}

div.footer * li.socialicons {
  font-size: 1.25rem;
}

div.footer * a {
  color: white;
}

div.vendorTop {
  background-color: black;
  color: white;
}

div.cart-img-portrait {
  /*height: 120px;*/
  width: 80px;
}

div.home-freq-img-landscape {
  width: 160px;
}

div.cart-img-vendor {
  height: 40px;
  width: 40px;
}

img.fill-parent {
  max-width: 100%;
  max-height: 100%;
}

a.link-inherit {
  color: inherit;
  text-decoration: none;
}

div.slidemenu {
  position: fixed;
  top: 0;
  height: 100vh;
  background: black;
  z-index: 9998;
  overflow-x: hidden;
  margin-top: 60px;
  padding-top: 40px;
  transition: width 0.3s;
  color: white;
}

div.slidemenu a {
  overflow-x: hidden;
  display: block;
  color: white;
  margin-left: 20px;
  font-size: 1.5rem;
  font-weight: bold;
}

div.mapsearchbox {
  position: relative;
  top: 10px;
}

div.fullscreen {
  width: 100vw;
  z-index: 9999;
  margin-top: 60px;
  padding-bottom: 15px;
}

div.slidingPages {
  overflow-x: hidden;
  white-space: nowrap;
  transition: margin 0.3s;
}

div.slidingPages > div {
  width: 100vw;
  display: inline-block;
  vertical-align: top;
}

div.dialog-background {
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  transition: 0.5s ease-in-out;
  z-index:10000;
}

div.dialog-background-loaded {
  background-color:rgba(0, 0, 0, 0.4);
}

div.dialog-foreground {
  position: absolute;
  top: 100vh;
  left:0;
  right:0;
  background-color: white;
  z-index:10100;
  display: block;
  /*height: 100px;*/
  transition: 0.5s ease-in-out;
  /*transform: translateY(-100%);*/
  Box-shadow: 0 -1px 3px rgba(0,0,0,.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

div.dialog-foreground-visible {
  transform: translateY(-100%);
}

div.dialog-title {
  background-color: #f5f5f5;
}
